import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en";
import nl from "./nl";

//
Vue.use(VueI18n);

//
let defaultLocale = localStorage.getItem('te-box-office-locale') || 'en';

//
export default new VueI18n({
  // locale: "en", // set locale
  locale: defaultLocale, // set locale
  messages: {
    en: en,
    nl: nl
  }
});

