
export const LOGIN = "login";
export const GET_USER = "get-user";
export const SEARCHING = "searching";

export const GET_EVENT = "get-event";
export const GET_EVENTS = "get-events";
export const GET_EVENT_CAPACITY_SUMMERY = "get-event-capacity-summery";
export const GET_EVENT_CAPACITY_DEFINITION = "get-event-capacity-definition";

export const GET_PRODUCT_DEFINITION = "get-product-definition";
export const GET_PRODUCT_DEFINITIONS = "get-product-definitions";

export const GET_PRICE_LIST = "get-price-list";

export const GET_CUSTOMER = "get-customer";
export const GET_CUSTOMER_ORDERS = "get-customer-orders";
export const GET_CUSTOMER_MEMBERSHIPS = "get-customer-memberships";
export const SAVE_CUSTOMER = "save-customer";

export const GET_EMAIL_TEMPLATE = "get-email-template";
export const GET_DOCUMENT_TEMPLATE = "get-document-template";
export const GET_PAYMENT_METHODS = "get-payment-methods";
export const GET_REFUND_METHODS = "get-refund-methods";
export const GET_ORDER_MESSAGES = "get-order-messages";
export const GET_TAGS = "get-tags";

export const GET_ORDER_ACCESS = "get-order-access";
export const GET_REGISTER_ORDERS = "get-register-orders";
export const GET_REGISTER_LEDGER = "get-register-ledger";
export const GET_EVENT_ATTENDEES = "get-event-attendees";

export const GET_SALES_CHANNEL = "get-sales-channel";

export const GET_ORDER = "get-order";
export const CREATE_ORDER = "create-order";
export const CANCEL_ORDER = "cancel-order";
export const ADD_ORDER_TOKEN = "add-order-token";
export const CHECKOUT_ORDER = "checkout-order";
export const DELIVER_ORDER = "deliver-order";

export const CHECK_PENDING_ORDER_LINES = "check-pending-order-lines";
export const SAVE_ORDER_LINE = "save-order-line";

export const CREATE_PAYMENT = "create-payment";
export const REFUND_PAYMENT = "refund-payment";
