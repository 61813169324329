import {Cart} from "ticketengine-checkout-sdk";

export default new Cart({
    authApiUrl: config.VUE_APP_AUTH_URL || process.env.VUE_APP_AUTH_URL,
    adminApiUrl: config.VUE_APP_ADMIN_API_URL || process.env.VUE_APP_ADMIN_API_URL,
    graphApiUrl: config.VUE_APP_GRAPH_API_URL || process.env.VUE_APP_GRAPH_API_URL,
    clientId: 'ticket_engine_back_office',
    clientSecret: 'fn4ZKSGyv34cwz5DYNXcUqheX63NRWgn',
    scope: 'order:write admin.order:write order:read order:reserve order:return payment:write payment:refund admin.payment:write event:read product_definition:read tag:read capacity:read customer:read customer:write sales_chanel:read search:read email:read email:write document:read access:read product:read document:write',
    clearTokenOnSetAuthUrl: false,
});

