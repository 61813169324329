import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import moment from 'moment';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: {message: null},
    // error: {message: 'some error'},
    jobs: [],
    user: null,
    locale: null,
    order: null,
    event: null,
    // eventCapacityDefinition: [],
    // eventCapacitySummery: [],
    events: [],
    productDefinition: null,
    productDefinitions: [],
    priceList: [],
    debugPriceList: [],
    catalogueView: 'event',
    catalogueFilter: {
      date: moment().toDate(),
      name: null,
      location: null,
      locationOperator: 'regex',
    },
    searchResults: [],
    customer: null,
    customerOrders: [],
    customerMemberships: [],
    salesChannelId: null,
    registerId: null,
    salesChannels: [],
    tags: [],
    emailTemplates: [],
    documentTemplates: [],
    paymentMethods: [],
    refundMethods: [],
    orderMessages: [],
    orderAccess: [],
    registerOrders: [],
    registerLedger: [],
    eventAttendees: [],
    // customerId: null,
    // salesChannelId: null,
    // registerId: null,
    // salesChannelId: '0bac5cb2-57cd-11ea-bfcd-0242ac15000f',
    // registerId: '2379002a-57cd-11ea-86c3-0242ac15000f',
  },
  mutations,
  actions,
  getters,
  modules: {
  }
})
