import cart from "@/services/cart";

export default {
    error: state => state.error,
    jobs: state => state.jobs,
    user: state => state.user,
    event: state => state.event,

    locale: (state) => {
        // return state.locale || 'en';
        return state.locale || localStorage.getItem('te-box-office-locale') || 'en';
    },

    // eventCapacitySummery: state => state.eventCapacitySummery,
    eventCapacitySummery: state => state.event && state.event.capacityLocationSummery ? state.event.capacityLocationSummery : [],
    // eventCapacityDefinition: state => state.eventCapacityDefinition,
    events: state => state.events,
    productDefinition: state => state.productDefinition,
    productDefinitions: state => state.productDefinitions,
    emailTemplates: state => state.emailTemplates,
    documentTemplates: state => state.documentTemplates,
    paymentMethods: state => state.paymentMethods,
    refundMethods: state => state.refundMethods,
    orderMessages: state => state.orderMessages,
    orderAccess: state => state.orderAccess,
    order: state => {
        return state.order;
        // if(state.order) {
        //     return state.order;
        // }
        // try {
        //     if(localStorage.getItem("te-order")) {
        //         const order = JSON.parse(localStorage.getItem("te-order"));
        //         if(order) {
        //             return order;
        //         }
        //     }
        // } catch (e) {
        //     console.error(e);
        // }
        // return null;
    },
    orderId: state => {
        if(state.order) {
            return state.order.id;
        }
        if(cart.hasOrder()) {
            return cart.getOrderId();
        }
        return null
    },
    registerOrders: state => state.registerOrders,
    registerLedger: state => state.registerLedger,
    eventAttendees: state => state.eventAttendees,
    priceList: state => state.priceList,
    debugPriceList: state => state.debugPriceList,
    catalogueView: state => state.catalogueView,
    catalogueViewIsEvent: state => state.catalogueView === 'event',
    catalogueViewIsProduct: state => state.catalogueView === 'product',
    // catalogueFilter: state => state.catalogueFilter,
    catalogueFilter: (state) => {
        const filter = localStorage.getItem('te-box-office-filter');
        const parsedFilter = filter ? JSON.parse(filter) : null;

        if(parsedFilter && parsedFilter.location) state.catalogueFilter.location = parsedFilter.location;
        if(parsedFilter && parsedFilter.locationOperator) state.catalogueFilter.locationOperator = parsedFilter.locationOperator;

//         return filter ? JSON.parse(filter) : state.catalogueFilter;
        return state.catalogueFilter;
    },
    searchResults: state => state.searchResults,
    customer: state => state.customer,
    customerOrders: state => state.customerOrders,
    customerMemberships: state => state.customerMemberships,
    //customerId: state => state.customerId,
    customerId: state => {
        // if(state.customer) {
        //     return state.customer.id;
        // }
        if(state.customer && state.customer.id) {
            return state.customer.id;
        }
        if(cart.hasCustomerId()) {
            return cart.getCustomerId();
        }
        return null
    },
    // salesChannelId: state => state.salesChannelId,
    salesChannel: state => state.salesChannel,
    salesChannels: state => state.salesChannels,
    tags: state => state.tags,
    salesChannelId: state => {
        // if(localStorage.getItem("te-sales-channel-id")) {
        //     return localStorage.getItem("te-sales-channel-id");
        // }
        if(state.salesChannelId) {
            return state.salesChannelId;
        }
        if(cart.hasSalesChannelId()) {
            return cart.getSalesChannelId();
        }
        return null
    },
    // registerId: state => state.registerId,
    registerId: state => {
        // if(localStorage.getItem("te-register-id")) {
        //     return localStorage.getItem("te-register-id");
        // }
        if(state.registerId) {
            return state.registerId;
        }
        if(cart.hasRegisterId()) {
            return cart.getRegisterId();
        }
        return null
    },
    registerName: state => {
        if(state.salesChannels) {
            const salesChannel = state.salesChannels.find(sc => sc.id === cart.getSalesChannelId());
            const register = (salesChannel && salesChannel.registers && Array.isArray(salesChannel.registers)) ? salesChannel.registers.find(r => r.id === cart.getRegisterId()) : null;
            if(register && register.name) return register.name;
        }
        return 'Unknown';
    },
};
