import * as types from "./mutation-types";
import moment from "moment";

export default {
    [types.CART_ERROR](state, {message}) {
        state.error = { message: message };
    },
    [types.FLUSH_CART_ERROR](state) {
        state.error = { message: null };
    },


    [types.START_CART_JOB](state, key) {
        if(state.jobs.indexOf(key) === -1) {
            state.jobs.push(key);
        }
    },
    [types.END_CART_JOB](state, key) {
        state.jobs = state.jobs.filter(job => job !== key);
    },


    [types.RECEIVED_USER](state, user) {
        state.user = user;
    },
    [types.CLEAR_USER](state) {
        state.user = null;
    },



    [types.SET_LOCALE](state, locale) {
        state.locale = locale;
        localStorage.setItem('te-box-office-locale', locale);
    },



    // [types.SET_CUSTOMER_ID](state, customerId) {
    //     localStorage.setItem("te-customer-id", customerId);
    // },
    [types.SET_SALES_CHANNEL_ID](state, salesChannelId) {
        state.salesChannelId = salesChannelId;
    },
    [types.SET_REGISTER_ID](state, registerId) {
        state.registerId = registerId;
    },
    [types.RECEIVED_SALES_CHANNELS](state, salesChannels) {
        state.salesChannels = salesChannels;
    },
    [types.CLEAR_SALES_CHANNELS](state) {
        state.salesChannels = [];
    },
    [types.CLEAR_SALES_CHANNEL_ID](state) {
        state.salesChannelId = null;
    },
    [types.CLEAR_REGISTER_ID](state) {
        state.registerId = null;
    },


    [types.RECEIVED_TAGS](state, tags) {
        state.tags = tags;
    },


    [types.RECEIVED_CUSTOMER](state, customer) {
        state.customer = customer;
    },
    [types.CLEAR_CUSTOMER](state) {
        state.customer = null;
    },
    [types.RECEIVED_CUSTOMER_ORDERS](state, orders) {
        state.customerOrders = orders;
    },
    [types.RECEIVED_CUSTOMER_MEMBERSHIPS](state, memberships) {
        state.customerMemberships = memberships;
    },
    [types.CLEAR_CUSTOMER_ORDERS](state) {
        state.customerOrders = null;
    },


    [types.RECEIVED_EMAIL_TEMPLATES](state, emailTemplates) {
        state.emailTemplates = emailTemplates;
    },
    [types.RECEIVED_DOCUMENT_TEMPLATES](state, documentTemplates) {
        state.documentTemplates = documentTemplates;
    },
    [types.RECEIVED_PAYMENT_METHODS](state, paymentMethods) {
        state.paymentMethods = paymentMethods;
    },
    [types.RECEIVED_REFUND_METHODS](state, refundMethods) {
        state.refundMethods = refundMethods;
    },
    [types.RECEIVED_ORDER_MESSAGES](state, orderMessages) {
        state.orderMessages = orderMessages;
    },
    [types.CLEAR_ORDER_MESSAGES](state) {
        state.orderMessages = [];
    },


    [types.RECEIVED_ORDER_ACCESS](state, orderAccess) {
        state.orderAccess = orderAccess;
    },

    [types.RECEIVED_REGISTER_ORDERS](state, registerOrders) {
        state.registerOrders = registerOrders;
    },
    [types.RECEIVED_REGISTER_LEDGER](state, registerLedger) {
        state.registerLedger = registerLedger;
    },
    [types.RECEIVED_EVENT_ATTENDEES](state, eventAttendees) {
        state.eventAttendees = eventAttendees;
    },


    [types.RECEIVED_PRICE_LIST](state, priceList) {
        state.priceList = priceList;
    },
    [types.CLEAR_PRICE_LIST](state) {
        state.priceList = [];
    },
    [types.RECEIVED_DEBUG_PRICE_LIST](state, debugPriceList) {
        state.debugPriceList = debugPriceList;
    },
    [types.CLEAR_DEBUG_PRICE_LIST](state) {
        state.debugPriceList = [];
    },


    [types.RECEIVED_EVENT](state, event) {
        state.event = event;
    },
    [types.CLEAR_EVENT](state) {
        state.event = null;
    },
    [types.RECEIVED_EVENTS](state, events) {
        state.events = events;
    },
    [types.CLEAR_EVENT](state) {
        state.events = [];
    },
    // [types.RECEIVED_EVENT_CAPACITY_SUMMERY](state, eventCapacitySummery) {
    //     state.eventCapacitySummery = eventCapacitySummery;
    // },
    // [types.CLEAR_EVENT_CAPACITY_SUMMERY](state) {
    //     state.eventCapacitySummery = [];
    // },
    // [types.RECEIVED_EVENT_CAPACITY_DEFINITION](state, eventCapacityDefinition) {
    //     state.eventCapacityDefinition = eventCapacityDefinition;
    // },
    // [types.CLEAR_EVENT_CAPACITY_DEFINITION](state) {
    //     state.eventCapacityDefinition = [];
    // },




    [types.RECEIVED_PRODUCT_DEFINITION](state, productDefinition) {
        state.productDefinition = productDefinition;
    },
    [types.CLEAR_PRODUCT_DEFINITION](state) {
        state.productDefinition = null;
    },
    [types.RECEIVED_PRODUCT_DEFINITIONS](state, productDefinitions) {
        state.productDefinitions = productDefinitions;
    },
    [types.CLEAR_PRODUCT_DEFINITIONS](state) {
        state.productDefinitions = [];
    },



    // [types.ORDER_CREATED](state, {salesChannelId, registerId, customerId, orderId}) {
    //     state.order = {
    //         id: orderId,
    //         status: 'pending',
    //         salesChannelId,
    //         registerId,
    //         customer: {id: customerId},
    //         lineItems: []
    //     };
    //     // const order = {
    //     //     id: orderId,
    //     //     status: 'pending',
    //     //     salesChannelId,
    //     //     registerId,
    //     //     customer: {id: customerId},
    //     //     lineItems: []
    //     // };
    //     // localStorage.setItem("te-order", JSON.stringify(state.order));
    //     localStorage.setItem("te-order-id", orderId);
    // },

    // [types.SET_ORDER_ID](state, orderId) {
    //     localStorage.setItem("te-order-id", orderId);
    // },

    [types.SET_CATALOGUE_VIEW](state, view) {
        state.catalogueView = view;
    },
    [types.SET_CATALOGUE_FILTER](state, filter) {
        state.catalogueFilter = filter;
        localStorage.setItem('te-box-office-filter', JSON.stringify(state.catalogueFilter));
    },
    [types.CLEAR_CATALOGUE_FILTER](state) {
        state.catalogueFilter = {
            date: moment().toDate(),
            name: null,
            // location: null,
            // locationOperator: 'regex',
            location: state.catalogueFilter.location,
            locationOperator: state.catalogueFilter.locationOperator,
        };
        localStorage.setItem('te-box-office-filter', JSON.stringify(state.catalogueFilter));
    },

    [types.RECEIVED_SEARCH_RESULTS](state, results) {
        state.searchResults = results;
    },
    [types.CLEAR_SEARCH_RESULTS](state) {
        state.searchResults = [];
    },

    [types.RECEIVED_ORDER](state, order) {
        state.order = order;

        // // remove canceled order id from local storage
        // if(state.order && state.order.status && state.order.status === 'canceled') {
        //     localStorage.removeItem("te-order-id");
        // }
    },

    // [types.ADDED_ACCESS_TO_CART](state, access) {
        // if(localStorage.getItem("te-order")) {
        //     const order = JSON.parse(localStorage.getItem("te-order"));
        //     if(order && order.lineItems) {
        //         order.lineItems.push(access);
        //         state.order = order;
        //         localStorage.setItem("te-order", JSON.stringify(order));
        //     }
        // }
    // },

    // [types.REMOVED_ACCESS_TO_CART](state, access) {
    //     state.cart = state.cart.filter(orderLine => orderLine !== key);
    // },

    // [types.REDIRECT_TO_PAYMENT](state) {
    //     if(state.order && state.order.status) state.order.status = 'redirectToPayment';
    //     // localStorage.removeItem("te-order-id");
    // },

    [types.CLEAR_ORDER](state) {
        state.order = null;
        // localStorage.removeItem("te-order-id");
    },


    // [types.EMPTY_CART](state) {
    //     state.cart = [];
    // },

};
