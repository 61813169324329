export const CART_ERROR = "CART_ERROR";
export const FLUSH_CART_ERROR = "FLUSH_CART_ERROR";
export const START_CART_JOB = "START_CART_JOB";
export const END_CART_JOB = "END_CART_JOB";

export const RECEIVED_USER = "RECEIVED_USER";
export const CLEAR_USER = "CLEAR_USER";

export const SET_LOCALE = "SET_LOCALE";

export const SET_CUSTOMER_ID = "SET_CUSTOMER_ID";
export const SET_SALES_CHANNEL_ID = "SET_SALES_CHANNEL_ID";
export const SET_REGISTER_ID = "SET_REGISTER_ID";
export const CLEAR_SALES_CHANNEL_ID = "CLEAR_SALES_CHANNEL_ID";
export const CLEAR_REGISTER_ID = "CLEAR_REGISTER_ID";

export const RECEIVED_SALES_CHANNEL = "RECEIVED_SALES_CHANNEL";
export const CLEAR_SALES_CHANNEL = "CLEAR_SALES_CHANNEL";
export const RECEIVED_SALES_CHANNELS = "RECEIVED_SALES_CHANNELS";
export const CLEAR_SALES_CHANNELS = "CLEAR_SALES_CHANNELS";

export const RECEIVED_CUSTOMER = "RECEIVED_CUSTOMER";
export const CLEAR_CUSTOMER = "CLEAR_CUSTOMER";
export const RECEIVED_CUSTOMER_ORDERS = "RECEIVED_CUSTOMER_ORDERS";
export const RECEIVED_CUSTOMER_MEMBERSHIPS = "RECEIVED_CUSTOMER_MEMBERSHIPS";
export const CLEAR_CUSTOMER_ORDERS = "CLEAR_CUSTOMER_ORDERS";

export const RECEIVED_EMAIL_TEMPLATES = "RECEIVED_EMAIL_TEMPLATES";
export const RECEIVED_DOCUMENT_TEMPLATES = "RECEIVED_DOCUMENT_TEMPLATES";
export const RECEIVED_PAYMENT_METHODS = "RECEIVED_PAYMENT_METHODS";
export const RECEIVED_REFUND_METHODS = "RECEIVED_REFUND_METHODS";
export const RECEIVED_ORDER_MESSAGES = "RECEIVED_ORDER_MESSAGES";
export const CLEAR_ORDER_MESSAGES = "CLEAR_ORDER_MESSAGES";
export const RECEIVED_TAGS = "RECEIVED_TAGS";

export const RECEIVED_ORDER_ACCESS = "RECEIVED_ORDER_ACCESS";
export const RECEIVED_REGISTER_ORDERS = "RECEIVED_REGISTER_ORDERS";
export const RECEIVED_REGISTER_LEDGER = "RECEIVED_REGISTER_LEDGER";
export const RECEIVED_EVENT_ATTENDEES = "RECEIVED_EVENT_ATTENDEES";

export const RECEIVED_PRICE_LIST = "RECEIVED_PRICE_LIST";
export const CLEAR_PRICE_LIST = "CLEAR_PRICE_LIST";
export const RECEIVED_DEBUG_PRICE_LIST = "RECEIVED_DEBUG_PRICE_LIST";
export const CLEAR_DEBUG_PRICE_LIST = "CLEAR_DEBUG_PRICE_LIST";

export const RECEIVED_EVENT = "RECEIVED_EVENT";
export const CLEAR_EVENT = "CLEAR_EVENT";
export const RECEIVED_EVENTS = "RECEIVED_EVENTS";
export const CLEAR_EVENTS = "CLEAR_EVENTS";
// export const RECEIVED_EVENT_CAPACITY_SUMMERY = "RECEIVED_EVENT_CAPACITY_SUMMERY";
// export const CLEAR_EVENT_CAPACITY_SUMMERY = "CLEAR_EVENT_CAPACITY_SUMMERY";
// export const RECEIVED_EVENT_CAPACITY_DEFINITION = "RECEIVED_EVENT_CAPACITY_DEFINITION";
// export const CLEAR_EVENT_CAPACITY_DEFINITION = "CLEAR_EVENT_CAPACITY_DEFINITION";

export const RECEIVED_PRODUCT_DEFINITION = "RECEIVED_PRODUCT_DEFINITION";
export const CLEAR_PRODUCT_DEFINITION = "CLEAR_PRODUCT_DEFINITION";
export const RECEIVED_PRODUCT_DEFINITIONS = "RECEIVED_PRODUCT_DEFINITIONS";
export const CLEAR_PRODUCT_DEFINITIONS = "CLEAR_PRODUCT_DEFINITIONS";

export const ORDER_CREATED = "ORDER_CREATED";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const RECEIVED_ORDER = "RECEIVED_ORDER";
export const ADDED_ACCESS_TO_CART = "ADDED_ACCESS_TO_CART";
export const REMOVED_ACCESS_FROM_CART = "REMOVED_ACCESS_FROM_CART";
export const REDIRECT_TO_PAYMENT = "REDIRECT_TO_PAYMENT";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const EMPTY_CART = "EMPTY_CART";

export const SET_CATALOGUE_VIEW = "SET_CATALOGUE_VIEW";
export const SET_CATALOGUE_FILTER = "SET_CATALOGUE_FILTER";
export const CLEAR_CATALOGUE_FILTER = "CLEAR_CATALOGUE_FILTER";
export const RECEIVED_SEARCH_RESULTS = "RECEIVED_SEARCH_RESULTS";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";
