import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "@/locals/index";

Vue.config.productionTip = false;



import "@/styles/index.scss";


import 'vue2-datepicker/index.css';


import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";
import FormDateTime from "@ticketengine/components/src/components/Form/FormDateTime";
Vue.component("fieldTeDateTime", FormDateTime);
Vue.component("multiselect", Multiselect);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import VModal from "vue-js-modal";
Vue.use(VModal);

import Toasted from "vue-toasted";
Vue.use(Toasted, {
  theme: "tec-toasted",
  containerClass: "tec-toasted-container"
});

import Unicon from "vue-unicons";
import {
  uniArrowLeft,
  uniArrowRight,
  uniTicket,
  uniCopy,
  uniAngleUp,
  uniAngleDown,
  uniSearch,
  uniFilter,
  uniTrashAlt,
  uniCheck,
  uniTimes,
  uniClock,
  uniTagAlt,
  uniPlus,
  uniCalendarAlt,
  uniLocationPoint,
  uniUsersAlt,
  uniDropbox,
  uniInvoice,
  uniUserSquare,
  uniExport
} from "vue-unicons/src/icons";

Unicon.add([
  uniArrowLeft,
  uniArrowRight,
  uniAngleUp,
  uniAngleDown,
  uniTicket,
  uniCopy,
  uniSearch,
  uniFilter,
  uniTrashAlt,
  uniCheck,
  uniTimes,
  uniClock,
  uniPlus,
  uniTagAlt,
  uniCalendarAlt,
  uniLocationPoint,
  uniUsersAlt,
  uniDropbox,
  uniInvoice,
  uniUserSquare,
  uniExport
]);
Vue.use(Unicon);




new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
