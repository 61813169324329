import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '@/views/Login';
// import SalesChannel from '@/views/SalesChannel';
// import ShoppingCart from '@/views/ShoppingCart';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: 'login' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    // component: Login
  },
  {
    path: '/debug/:type/:id',
    name: 'debug',
    component: () => import(/* webpackChunkName: "login" */ '../views/Debug.vue')
  },
  {
    path: '/channel',
    name: 'channel',
    component: () => import(/* webpackChunkName: "saleschannel" */ '../views/SalesChannel.vue')
    // component: SalesChannel
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: "shoppingcart" */ '../views/ShoppingCart.vue')
    // component: ShoppingCart
  },
  {
    path: '/print-preview/:orderId',
    name: 'order-print-preview',
    component: () => import(/* webpackChunkName: "login" */ '../views/OrderPrintPreview.vue')
    // component: Login
  },
  {
    path: '/register-order-history/',
    name: 'register-order-history',
    component: () => import(/* webpackChunkName: "order-history" */ '../views/RegisterOrderHistory.vue')
    // component: Login
  },
  {
    path: '/register-ledger/',
    name: 'register-ledger',
    component: () => import(/* webpackChunkName: "ledger" */ '../views/RegisterLedger.vue')
    // component: Login
  },
  // {
  //   path: '/customer/:customerId/history',
  //   name: 'customer-order-history',
  //   component: () => import(/* webpackChunkName: "order-history" */ '../views/CustomerOrderHistory.vue')
  // },
  {
    path: '/customer/:customerId',
    name: 'customer',
    component: () => import(/* webpackChunkName: "customer-detail" */ '../views/CustomerDetail.vue'),
    children: [
      {
        path: "detail",
        name: "customer-detail",
        components: {
          detail: () =>
              import(/* webpackChunkName: "customer-detail" */ "../components/CustomerEditor.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "history",
        name: "customer-order-history",
        components: {
          detail: () =>
              import(/* webpackChunkName: "customer-detail" */ "../components/CustomerOrderHistory.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      },
      {
        path: "membership",
        name: "customer-membership",
        components: {
          detail: () =>
              import(/* webpackChunkName: "customer-detail" */ "../components/CustomerMembership.vue") // route level code-splitting this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
        }
      }
    ]
  },
  {
    path: '/event-attendees/:eventId',
    name: 'event-attendees',
    component: () => import(/* webpackChunkName: "login" */ '../views/EventAttendees.vue')
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
