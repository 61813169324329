import * as mutationTypes from "./mutation-types";
import * as jobTypes from "./job-types";
import router from "@/router";
// import {Cart} from "ticketengine-checkout-sdk";
import {WebClient} from "ticketengine-sdk";
import moment from 'moment';
import print from "print-js";
import {uniAward} from "vue-unicons/src/icons";
// import BackendService from "@/services/backend";
// import check from "@/services/check";
import cart from "@/services/cart";
import {HasStatus} from "ticketengine-checkout-sdk";

// const backoffice = new BackendService();

// const cart = new Cart({
//     authApiUrl: config.VUE_APP_AUTH_URL || process.env.VUE_APP_AUTH_URL,
//     adminApiUrl: config.VUE_APP_ADMIN_API_URL || process.env.VUE_APP_ADMIN_API_URL,
//     graphApiUrl: config.VUE_APP_GRAPH_API_URL || process.env.VUE_APP_GRAPH_API_URL,
//     clientId: 'ticket_engine_back_office',
//     clientSecret: 'fn4ZKSGyv34cwz5DYNXcUqheX63NRWgn',
//     scope: 'order:write admin.order:write order:read payment:write admin.payment:write event:read customer:read sales_chanel:read search:read',
// });
const client = new WebClient({
    authUrl: config.VUE_APP_AUTH_URL || process.env.VUE_APP_AUTH_URL,
    adminApiUrl: config.VUE_APP_ADMIN_API_URL || process.env.VUE_APP_ADMIN_API_URL,
    graphApiUrl: config.VUE_APP_GRAPH_API_URL || process.env.VUE_APP_GRAPH_API_URL,
    clearTokenOnSetAuthUrl: false,
    oauthScope: 'order:write admin.order:write order:read order:reserve order:return payment:write payment:refund admin.payment:write event:read capacity:read product_definition:read tag:read customer:read customer:write sales_chanel:read search:read email:read email:write document:read access:read product:read membership:read document:write',
});

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
};


const checkIfDeliveryReadModelIsFinished = async (orderId, desiredOrderStatus, retryPolicy) => {
    // query order read model tha this used by delivery
    const query = `query { order(id: "${orderId}"){id,status,lineItems{ ... on AccessLineItem {id,type,status} ... on ProductLineItem {id,type,status} }} }`;
    const response = await client.sendQuery(query, [1000]);

    // check if order in read model is completed
    // if not in desired state, retry query
    if(response.data.order && response.data.order.status === desiredOrderStatus) {
        // console.log('Delivery read model is finished.');
        return true;
    }

    // retry until abort
    const sleepTime = retryPolicy.shift();
    if(sleepTime === undefined) throw new Error('Could not deliver order. Please try again later'); // abort retry, retries attempts exceeded
    await sleep(sleepTime); // wait x milliseconds
    return await checkIfDeliveryReadModelIsFinished(orderId, desiredOrderStatus, retryPolicy) // retry
};



export default {


    async signIn({ commit, dispatch }, {username, password}) {
        dispatch('flushError');
        try {
            await cart.login(username, password);
            router.push({ name: "channel" });
        } catch (error) {
            dispatch('onError', {error});
        }
    },

    async getUser({ commit, dispatch, state }) {
        if(state.user === null) {
            commit(mutationTypes.START_CART_JOB, jobTypes.GET_USER);
            commit(mutationTypes.CLEAR_USER);
            try {
                const query = `query { me{id,username,scopes} }`;
                const response = await client.sendQuery(query, [1000]);
                commit(mutationTypes.RECEIVED_USER, response.data.me);
            } catch (error) {
                dispatch('onError', {error});
            }
            commit(mutationTypes.END_CART_JOB, jobTypes.GET_USER);
        }
    },

    async clearUser({ commit }) {
        commit(mutationTypes.CLEAR_USER);
    },



    async setLocale({ commit }, {locale}) {
        commit(mutationTypes.SET_LOCALE, locale);
    },

    setCatalogueView({ commit }, {view}) {
        commit(mutationTypes.SET_CATALOGUE_VIEW, view);
    },

    async setCatalogueFilter({ commit }, filter) {
        commit(mutationTypes.SET_CATALOGUE_FILTER, filter);
    },

    async clearCatalogueFilter({ commit }) {
        commit(mutationTypes.CLEAR_CATALOGUE_FILTER);
    },

    async search ({ commit, dispatch }, {searchQuery, entity}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.SEARCHING);
        commit(mutationTypes.CLEAR_SEARCH_RESULTS);
        try {
            let entityFilter = '';
            if(entity !== null) {
                entityFilter = `, type: "${entity}"`;
            }
            const query = `query { search(searchTerm: "${searchQuery}"${entityFilter}){id,name,description,type,status,date,context{orderId,customerId}} }`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_SEARCH_RESULTS, response.data.search);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.SEARCHING);
    },



    async getSalesChannels({ commit, dispatch }) {
        // dispatch('clearSalesChannels');
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_SALES_CHANNEL);
        try {
            // const query = `query { salesChannels{id,name,description,registers{id,name,description}} }`;
            const query = `query { me{salesChannels{id,name,description,registers{id,name,description}}} }`;
            const response = await client.sendQuery(query, [1000]);
            // commit(mutationTypes.RECEIVED_SALES_CHANNELS, response.data.salesChannels);
            commit(mutationTypes.RECEIVED_SALES_CHANNELS, response.data.me.salesChannels);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_SALES_CHANNEL);
    },

    setSalesChannelId({ commit }, salesChannelId) {
        commit(mutationTypes.SET_SALES_CHANNEL_ID, salesChannelId);
        cart.setSalesChannelId(salesChannelId);
        // commit(mutationTypes.SET_SALES_CHANNEL_ID, salesChannelId);
    },

    setRegisterId({ commit }, registerId) {
        commit(mutationTypes.SET_REGISTER_ID, registerId);
        cart.setRegisterId(registerId);
        // commit(mutationTypes.SET_REGISTER_ID, registerId);
    },

    // clearSalesChannels({ commit }) {
    //     // cart.removeSalesChannelId();
    //     // commit(mutationTypes.CLEAR_SALES_CHANNELS);
    // },

    clearRegister({ commit, dispatch }) {
        cart.clearSalesChannelId();
        cart.clearRegisterId();
        commit(mutationTypes.CLEAR_SALES_CHANNELS);
        commit(mutationTypes.CLEAR_SALES_CHANNEL_ID);
        commit(mutationTypes.CLEAR_REGISTER_ID);
    },



    async getCustomer({ commit, dispatch }, {customerId}) {
        commit(mutationTypes.CLEAR_CUSTOMER);
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_CUSTOMER);
        try {
            if(!customerId) {
                customerId = cart.getCustomerId();
            }
            const query = `query { customer(id: "${customerId}"){id,firstName,lastName,fullName,sortName,company,jobTitle,birthDate,gender,email,phone,imageUrl,tags{id,name}} }`;
            const response = await client.sendQuery(query, [1000]);
            const customer = response.data.customer;
            // const customer = await cart.getCustomer(customerId);
            await cart.setCustomer(customerId);
            commit(mutationTypes.RECEIVED_CUSTOMER, customer);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_CUSTOMER);
    },

    async saveCustomer({ commit, dispatch }, {customer}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.SAVE_CUSTOMER);
        try {
            const response = await client.sendCommand('ChangeCustomer', customer);
            dispatch('getCustomer', {customerId: customer.id});
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.SAVE_CUSTOMER);
    },

    async createNewCustomer({ commit, dispatch }, {customer}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.SAVE_CUSTOMER);
        try {
            const response = await client.sendCommand('CreateCustomer', customer);
            dispatch('getCustomer', {customerId: response.data.customerId});
            router.push({ name: "customer-detail", params: {customerId: response.data.customerId} });
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.SAVE_CUSTOMER);
    },

    async clearCustomer({ commit }) {
        await cart.removeCustomer();
        commit(mutationTypes.CLEAR_CUSTOMER);
    },

    async getCustomerOrders({ commit, dispatch }, {customerId}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_CUSTOMER_ORDERS);
        try {
            if(!customerId) {
                customerId = cart.getCustomerId();
            }
            const query = `query { orders(filters: {customerId: {equals: "${customerId}"}}, first: 0, offset: 50, orderBy: createDate_DESC){id,registerId,status,paymentStatus,createDate,requiredPayments{currency{code,symbol,exponent},amount},lineItems{ ... on AccessLineItem {id,type,status,price,tax,currency{code,symbol,exponent},name,event{id,name,location,start,end}} ... on ProductLineItem {id,type,status,price,tax,currency{code,symbol,exponent},name,productDefinition{id,name},product{id,status}} },customer{id,fullName}}}`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_CUSTOMER_ORDERS, response.data.orders);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_CUSTOMER_ORDERS);
    },

    async getCustomerMemberships({ commit, dispatch }, {customerId}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_CUSTOMER_MEMBERSHIPS);
        try {
            if(!customerId) {
                customerId = cart.getCustomerId();
            }
            const query = `query { memberships(filters: {customerId: {equals: "${customerId}"}}){ id,status,orderId,orderLineItemId,token,grantDate,start,end,membershipDefinition{id,name} }}`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_CUSTOMER_MEMBERSHIPS, response.data.memberships);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_CUSTOMER_MEMBERSHIPS);
    },

    async clearCustomerOrders({ commit }) {
        commit(mutationTypes.CLEAR_CUSTOMER_ORDERS);
    },




    async getTags({ commit, dispatch }) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_TAGS);
        try {
            const query = `query { tags{id,name} }`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_TAGS, response.data.tags);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_TAGS);
    },



    async getEmailTemplates({ commit, dispatch }) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_EMAIL_TEMPLATE);
        try {
            const query = `query { emailTemplates(filters: {allowedRegisters: {contains: ["${cart.getRegisterId()}"]}}){id,name,subject} }`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_EMAIL_TEMPLATES, response.data.emailTemplates);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_EMAIL_TEMPLATE);
    },

    async getDocumentTemplates({ commit, dispatch }) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_DOCUMENT_TEMPLATE);
        try {
            const query = `query { documentTemplates(filters: {allowedRegisters: {contains: ["${cart.getRegisterId()}"]}}){id,name,type} }`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_DOCUMENT_TEMPLATES, response.data.documentTemplates);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_DOCUMENT_TEMPLATE);
    },



    async getPaymentMethods({ commit, dispatch, state }) {
        // if(state.paymentMethods === null || state.paymentMethods.length === 0) {
            commit(mutationTypes.START_CART_JOB, jobTypes.GET_PAYMENT_METHODS);
            try {
                const query = `query { alternativePaymentMethods(filters: {allowedRegisters: {contains: "${cart.getRegisterId()}"}, status: {contains: "enabled"}}){id,name,key}}`;
                const response = await client.sendQuery(query, [1000]);
                commit(mutationTypes.RECEIVED_PAYMENT_METHODS, response.data.alternativePaymentMethods);
            } catch (error) {
                dispatch('onError', {error});
            }
            commit(mutationTypes.END_CART_JOB, jobTypes.GET_PAYMENT_METHODS);
        // }
    },


    async getRefundMethods({ commit, dispatch, state }, {registerId}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_REFUND_METHODS);
        try {
            const query = `query { alternativePaymentMethods(filters: {allowedRegisters: {contains: "${registerId}"}, status: {contains: "enabled"}}){id,name,key}}`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_REFUND_METHODS, response.data.alternativePaymentMethods);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_REFUND_METHODS);
    },


    async getOrderMessages({ commit, dispatch, state }, {stage, orderId, eventId, productDefinitionId, customerId, salesChannelId, preferredLanguage}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_ORDER_MESSAGES);
        commit(mutationTypes.CLEAR_ORDER_MESSAGES);
        try {
            const orderMessages = await cart.getOrderMessages(
                stage,
                orderId ? orderId : null,
                eventId ? eventId : null,
                productDefinitionId ? productDefinitionId : null,
                customerId ? customerId : null,
                salesChannelId ? salesChannelId : null,
                preferredLanguage ? preferredLanguage : null
            );
            commit(mutationTypes.RECEIVED_ORDER_MESSAGES, orderMessages);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_ORDER_MESSAGES);
    },



    async getOrderAccess({ commit, dispatch }, {orderId}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_ORDER_ACCESS);
        try {
            const query = `query { accesses(filters: {orderId: {equals: "${orderId}"}, status: {contains: ["granted"]}}){id,start,end,location,useLimit,totalUsed,status,tokens,event{id,name,start,location}} }`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_ORDER_ACCESS, response.data.accesses);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_ORDER_ACCESS);
    },

    async getRegisterOrders({ commit, dispatch }) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_REGISTER_ORDERS);
        try {
            const query = `query { orders(filters: {registerId: {equals: "${cart.getRegisterId()}"}}, first: 0, offset: 20, orderBy: createDate_DESC){id,registerId,status,number,paymentStatus,createDate,requiredPayments{currency{code,symbol,exponent},amount},lineItems{ ... on AccessLineItem {id,type,status,price,tax,currency{code,symbol,exponent},name,event{id,name,location,start,end}} ... on ProductLineItem {id,type,status,price,tax,currency{code,symbol,exponent},name,productDefinition{id,name},product{id,status}} },customer{id,fullName}}}`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_REGISTER_ORDERS, response.data.orders);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_REGISTER_ORDERS);
    },

    async getRegisterLedger({ commit, dispatch }, {first, offset, startDate, endDate}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_REGISTER_LEDGER);
        try {
            // const query = `query { orders(filters:{status: {contains: "completed"}, registerId: {equals: "${cart.getRegisterId()}"}, createDate: {between: {start: "${startDate}", end: "${endDate}"}}}, first: ${first}, offset: ${offset}, orderBy: createDate_ASC){id,number,paymentStatus,createDate,status,tokens{id,typeId,token},customer{id,fullName},payments{id,currency{name,code,exponent,symbol},amount,status,method,psp,pspReference},requiredPayments{currency{name,code,exponent,symbol},amount},customer{id,fullName},lineItems{ ... on AccessLineItem {id,type,status,price,tax,currencyCode,currency{name,code,exponent,symbol},name,capacityLocationPath,requestedConditionPath,accessId,event{id,name,location,start,end}} ... on ProductLineItem {id,type,status,price,tax,currencyCode,currency{name,code,exponent,symbol},requestedConditionPath,productId,productDefinition{id,name},product{id,status}} }} }`;
            const query = `query { ledgerEntries(filters: {status: {equals: "authorized"}, registerId: {equals: "${cart.getRegisterId()}"}, createdOn: {between: {start: "${startDate}", end: "${endDate}"}}}){id,amount,currency{code,name,exponent,symbol},method,psp,pspReference,type,status,order{id,number},createdOn,changedOn} }`;
            const response = await client.sendQuery(query, [1000]);
            // commit(mutationTypes.RECEIVED_REGISTER_LEDGER, response.data.orders);
            commit(mutationTypes.RECEIVED_REGISTER_LEDGER, response.data.ledgerEntries);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_REGISTER_LEDGER);
    },

    async getEventAttendees({ commit, dispatch }, {eventId}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_EVENT_ATTENDEES);
        try {
            // const query = `query { orderLineItems(filters: {eventId: {equals: "${eventId}"}, status: {notContains: ["removed"]}}){ ... on AccessLineItem {id,orderId,type,status,price,tax,currency{code,symbol,exponent},name,customer{id,fullName,email}} ... on ProductLineItem {id,orderId,type,status,price,tax,currency{code,symbol,exponent},name,customer{id,fullName,email}} }}`;
            const query = `query { orderLineItems(filters: {eventId: {equals: "${eventId}"}, status: {notContains: ["removed"]}}){ ... on AccessLineItem {id,orderId,type,status,price,tax,currency{code,symbol,exponent},name,customer{id,fullName,email},order{id,number,createDate}} }}`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_EVENT_ATTENDEES, response.data.orderLineItems);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_EVENT_ATTENDEES);
    },



    async getEventPriceList({ commit, dispatch }, {eventId, customerId, salesChannelId, preferredLanguageCode, capacityLocationPath}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_PRICE_LIST);
        try {
            if(!cart.hasOrder()) {
                await cart.createOrder();
            }
            const prices = await cart.getEventPrices(eventId, customerId, salesChannelId, preferredLanguageCode, capacityLocationPath);
            commit(mutationTypes.RECEIVED_PRICE_LIST, prices);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_PRICE_LIST);
    },

    async getDebugEventPriceList({ commit, dispatch }, {eventId, customerId, salesChannelId, orderId}) {
        commit(mutationTypes.CLEAR_DEBUG_PRICE_LIST);
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_PRICE_LIST);
        try {
            const customerParam = customerId ? `, customerId: "${customerId}"` : '';
            const orderParam = orderId ? `, orderId: "${orderId}"` : '';
            const salesChannelParam = salesChannelId ? `, salesChannelId: "${salesChannelId}"` : '';
            const query = `query { eventPricesDebug(eventId: "${eventId}"${customerParam}${orderParam}${salesChannelParam}){satisfiedConditions,conditionsString,conditionsStringResolvedEntities,accessDefinitionId,accessDefinitionName,eventId,orderId,customerId,salesChannelId} }`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_DEBUG_PRICE_LIST, response.data.eventPricesDebug);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_PRICE_LIST);
    },

    async getEvent({ commit, dispatch }, {eventId}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_EVENT);
        try {
            const event = await cart.getEvent(eventId);
            commit(mutationTypes.RECEIVED_EVENT, event);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_EVENT);
    },

    async getEvents({ commit, dispatch }, {filter}) {
// console.log(filter);
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_EVENTS);
        try {
            const nameFilter = filter.name !== null && filter.name !== undefined ? `, name: {equals: "${filter.name}"}` : '';
            // const nameFilter = '';
            const locationFilter = (filter.location !== null && filter.location !== '' && filter.locationOperator !== null) ? `, location: {${filter.locationOperator}: "${filter.location}"}` : '';
            const statusFilter = `, status: {contains: ["published","canceled"]}`;
            // const query = `query { events(filters: {start: {gt: "${moment(filter.date).toISOString()}"}${nameFilter}${statusFilter}${locationFilter}}, first: 0, offset: 100, orderBy: start_ASC){id,eventManagerId,status,name,description,location,start,end,totalCapacity,availableCapacity,reservedCapacity,grantedCapacity,tags{id,name}} }`;
            const query = `query { events(filters: {end: {gt: "${moment(filter.date).toISOString()}"}${nameFilter}${statusFilter}${locationFilter}}, first: 0, offset: 100, orderBy: start_ASC){id,eventManagerId,status,name,description,location,start,end,totalCapacity,availableCapacity,reservedCapacity,grantedCapacity,tags{id,name}} }`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_EVENTS, response.data.events);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_EVENTS);
    },

    clearEvent({ commit }) {
        commit(mutationTypes.CLEAR_EVENT);
    },

    // async getEventCapacitySummery({ commit, dispatch }, {eventId}) {
    //     commit(mutationTypes.CLEAR_EVENT_CAPACITY_SUMMERY);
    //     commit(mutationTypes.START_CART_JOB, jobTypes.GET_EVENT_CAPACITY_SUMMERY);
    //     try {
    //         const query = `query{event(id:"${eventId}"){capacityLocationSummery{capacityLocationPath,name,capacity,issued,reserved,granted,available,used}}}`;
    //         const response = await client.sendQuery(query, [1000]);
    //         commit(mutationTypes.RECEIVED_EVENT_CAPACITY_SUMMERY, response.data.event.capacityLocationSummery);
    //     } catch (error) {
    //         dispatch('onError', {error});
    //     }
    //     commit(mutationTypes.END_CART_JOB, jobTypes.GET_EVENT_CAPACITY_SUMMERY);
    // },

    // async getEventCapacityDefinition({ commit, dispatch }, {eventId}) {
    //     commit(mutationTypes.CLEAR_EVENT_CAPACITY_DEFINITION);
    //     commit(mutationTypes.START_CART_JOB, jobTypes.GET_EVENT_CAPACITY_DEFINITION);
    //     try {
    //         const query = `query { capacityDefinitions(filters: {eventId: {equals: "${eventId}"}}) {id,eventManagerId,capacity} }`;
    //         const response = await client.sendQuery(query, [1000]);
    //         response.data.capacityDefinitions.forEach(cd => {
    //             cd.capacity = JSON.parse(cd.capacity);
    //         });
    //         commit(mutationTypes.RECEIVED_EVENT_CAPACITY_DEFINITION, response.data.capacityDefinitions);
    //     } catch (error) {
    //         dispatch('onError', {error});
    //     }
    //     commit(mutationTypes.END_CART_JOB, jobTypes.GET_EVENT_CAPACITY_DEFINITION);
    // },





    async getProductPriceList({ commit, dispatch }, {productDefinitionId, customerId, salesChannelId}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_PRICE_LIST);
        try {
            if(!cart.hasOrder()) {
                await cart.createOrder();
            }
            const prices = await cart.getProductPrices(productDefinitionId, customerId, salesChannelId);
            commit(mutationTypes.RECEIVED_PRICE_LIST, prices);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_PRICE_LIST);
    },

    async getProductDefinition({ commit, dispatch }, {productDefinitionId}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_PRODUCT_DEFINITION);
        try {
            const productDefinition = await cart.getProductDefinition(productDefinitionId);
            commit(mutationTypes.RECEIVED_PRODUCT_DEFINITION, productDefinition);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_PRODUCT_DEFINITION);
    },

    async getProductDefinitions({ commit, dispatch }, {filter}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_PRODUCT_DEFINITIONS);
        try {
            const nameFilter = filter.name !== null ? `, name: {equals: "${filter.name}"}` : '';
            const query = `query { productDefinitions(filters: {status: {contains: ["published"]}${nameFilter}}, first: 0, offset: 100, orderBy: name_ASC){ id,name,description,tags{id,name} }}`;
            const response = await client.sendQuery(query, [1000]);
            commit(mutationTypes.RECEIVED_PRODUCT_DEFINITIONS, response.data.productDefinitions);
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_PRODUCT_DEFINITIONS);
    },

    clearProductDefinition({ commit }) {
        commit(mutationTypes.CLEAR_PRODUCT_DEFINITION);
    },





    async getOrder({ commit, dispatch }, {orderId, validator, forceReload, redirectAfterLoad, forceOrder}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.GET_ORDER);
        try {
            if(!orderId) {
                orderId = cart.getOrderId();
            }
            const order = await cart.getOrder(orderId, validator, [500, 1000, 1000, 1000, 3000, 5000, 5000, 5000], forceReload, forceOrder);
            commit(mutationTypes.RECEIVED_ORDER, order);
            if(redirectAfterLoad) {
                await router.push(redirectAfterLoad);
            }
        } catch (error) {
            // const responseStatus = (error && error.response && error.response.status) ? error.response.status : null;
            // if(responseStatus === 404) {
            //     dispatch('getOrder', {orderId, validator, forceReload, redirectAfterLoad});
            // }
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.GET_ORDER);
    },


    async addItemsToCart({ commit, dispatch }, {items}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.CREATE_ORDER);
        try {
            await cart.addItems(items);
            // commit(mutationTypes.ORDER_CREATED, {orderId: response.data.orderId});
            dispatch('getOrder', {orderId: cart.getOrderId()});
        } catch (error) {
            dispatch('onError', {error});
            dispatch('getOrder', {orderId: cart.getOrderId()});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.CREATE_ORDER);
    },


    async addOrderToken({ commit, dispatch }, {token}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.ADD_ORDER_TOKEN);
        try {
            await cart.addToken(token);
            // commit(mutationTypes.ORDER_CREATED, {orderId: response.data.orderId});
            dispatch('getOrder', {orderId: cart.getOrderId()});
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.ADD_ORDER_TOKEN);
    },


    async changeCart({ commit, dispatch }, {addItems, removeItems}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.SAVE_ORDER_LINE);
        try {
            await cart.changeItems(addItems, removeItems);
            dispatch('getOrder', {orderId: cart.getOrderId()});
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.SAVE_ORDER_LINE);
    },


    async reserve({ commit, dispatch }, {email, templateId, timeoutOn}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.CHECKOUT_ORDER);
        try {
            await cart.reserve(email, timeoutOn);
            if(templateId && email) {
                dispatch('deliver', {orderId: cart.getOrderId(), deliveryMethod: 'email', email, templateId, desiredOrderStatus: 'reserved'});
            }
            dispatch('clearOrder');
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.CHECKOUT_ORDER);
    },


    async checkout({ commit, dispatch, state }, {payments, deliveryMethod, email, templateId, printOrderLineItemIds}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.CHECKOUT_ORDER);
        try {
            await cart.checkout(email, payments);

            const confirmedStatus = ['completed', 'reserved'];
            const validator = new HasStatus(confirmedStatus);
            await dispatch('getOrder', {orderId: cart.getOrderId(), validator, forceReload: true});
            if(state.order && state.order.status && confirmedStatus.includes(state.order.status)) {
                dispatch('deliver', {orderId: cart.getOrderId(), deliveryMethod, email, templateId, printOrderLineItemIds, desiredOrderStatus: 'completed'});
                dispatch('clearOrder');
                dispatch('getEvents', {filter: state.catalogueFilter});
            }
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.CHECKOUT_ORDER);
    },


    async deliver({ commit, dispatch }, {orderId, deliveryMethod, email, templateId, printOrderLineItemIds, desiredOrderStatus}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.DELIVER_ORDER);
        try {
            if(deliveryMethod === 'email' && email && templateId) {
                // await sleep(3000); // HACK: give projection time to project order before sending email
                const readModelsFinished = await checkIfDeliveryReadModelIsFinished(cart.getOrderId(), desiredOrderStatus, [500, 1000, 1000, 1000, 1000, 1000, 2000, 2000, 3000, 3000, 5000]);
                if(readModelsFinished === true) {
                    await client.email.sendEmailTemplate({
                        orderId,
                        templateId,
                        email
                        // }, [500, 1000, 1000, 1000, 3000, 3000, 3000]);
                    }, []);
                }
            }
            if(deliveryMethod === 'print' && templateId && printOrderLineItemIds && printOrderLineItemIds.length > 0) {
                // let routeData = router.resolve({name: 'order-print-preview', params: {orderId: orderId}});
                // window.open(routeData.href, '_blank');

                const readModelsFinished = await checkIfDeliveryReadModelIsFinished(cart.getOrderId(), desiredOrderStatus, [500, 1000, 1000, 1000, 1000, 1000, 2000, 2000, 3000, 3000, 5000]);
                if(readModelsFinished === true) {
                    dispatch('printItems', {templateId, orderLineItemIds: printOrderLineItemIds});
                }
            }
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.DELIVER_ORDER);
    },

    async cancelOrder({ commit, dispatch }, {orderId}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.CANCEL_ORDER);
        try {
            await cart.cancelOrder(orderId);
            dispatch('clearOrder');
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.CANCEL_ORDER);
        await cart.removeCustomer(); // also clear customer
    },


    async returnItems({ commit, dispatch }, {orderLineItemIds}) {
        // commit(mutationTypes.START_CART_JOB, jobTypes.SAVE_ORDER_LINE);
        // try {
        //     await this.client.order.returnItemInCart({
        //         aggregateId: this.getOrderId(),
        //         orderLineItemId: orderLineItemId
        //     }, [500, 500, 1000, 1000, 1000, 5000]);
        // } catch (error) {
        //     dispatch('onError', {error});
        // }
        // commit(mutationTypes.END_CART_JOB, jobTypes.SAVE_ORDER_LINE);

        const orderId = cart.getOrderId();
        commit(mutationTypes.START_CART_JOB, jobTypes.SAVE_ORDER_LINE);
        try {
            // execute all return commands
            const results = await Promise.all(
                orderLineItemIds.map(orderLineItemId => {
                    return client.order.returnItemInCart({
                        aggregateId: orderId,
                        orderLineItemId: orderLineItemId
                    }, [500, 500, 1000, 1000, 1000, 1000, 1000, 5000]);
                })
            );

            // check if there are commands that return an error
            let e = null;
            results.forEach(result => {
                if (result.error) {
                    dispatch('onError', {error});
                }
            });

            dispatch('getOrder', {orderId: orderId, forceReload: true});
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.SAVE_ORDER_LINE);
    },


    async refundItems({ commit, dispatch }, {refunds}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.REFUND_PAYMENT);
        const orderId = cart.getOrderId();
        try {
            // execute all refund commands
            const results = await Promise.all(
                refunds.map(refund => {
                    return client.payment.createPaymentRefund(refund, [500, 500, 1000, 1000, 1000, 1000, 1000, 5000]);
                })
            );

            // check if there are commands that return an error
            let e = null;
            results.forEach(result => {
                if (result.error) {
                    dispatch('onError', {error});
                }
            });

            dispatch('getOrder', {orderId: orderId, forceReload: true});
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.REFUND_PAYMENT);
    },


    async printItems({ commit, dispatch }, {templateId, orderLineItemIds}) {
        commit(mutationTypes.START_CART_JOB, jobTypes.DELIVER_ORDER);
        try {
            const response = await client.document.createDocument({
                templateId: templateId,
                ids: orderLineItemIds,
                multiple: false
            }, []);
            if(response.data.documents && response.data.documents.length > 0) {
// console.log('Print: ' + response.data.documents[0].url);
                print(response.data.documents[0].url);
            }
        } catch (error) {
            dispatch('onError', {error});
        }
        commit(mutationTypes.END_CART_JOB, jobTypes.DELIVER_ORDER);
    },



    clearOrder({ commit, dispatch }) {
        cart.clearOrder();
        commit(mutationTypes.CLEAR_ORDER);
        dispatch('clearCustomer');
    },






    // emptyCart({ commit }) {
    //     commit(mutationTypes.EMPTY_CART);
    // },

    onError({ commit }, {error}) {
        let message = 'Something went wrong.';
        const status = error && error.response && error.response.status ? error.response.status : null;
// console.error(error);
        if(status === 401 || status === 403) {
            router.push({ name: "login" });
        }
        if(error.message) message = error.message;
        if(error && error.response && error.response.data && error.response.data.data && error.response.data.data.message) {
            message = error.response.data.data.message;
        }
        if(error && error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message;
        }
        commit(mutationTypes.CART_ERROR, {message});
    },


    flushError({ commit }) {
        commit(mutationTypes.FLUSH_CART_ERROR);
    }
};
